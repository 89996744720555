import { AuthService } from './../services/auth.service';
import { Injectable } from '@angular/core';
import { CanActivate,  Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { take, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {

  constructor(private router: Router, private auth: AuthService) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const expextedRole = next.data.role;

    return this.auth.user.pipe(
      take(1),
      map(user => {
        if (!user) {
          return false;
        } else {
          const role = user['role'];
          if (expextedRole === role) {
            return true;
          } else {
            this.router.navigateByUrl('/');
            return false;
          }
        }
      })
    );
  }

}
