import { OrderService } from './order.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { Injectable } from '@angular/core';
import firebase from 'firebase/app';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';
import { NavController } from '@ionic/angular';

// https://www.positronx.io/full-angular-7-firebase-authentication-system/ take a look
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user: Observable<any>;
  public userObject;
  currentUserFirebase: firebase.User = null;
  currentUser = new BehaviorSubject(null);

  constructor(private afAuth: AngularFireAuth, private db: AngularFirestore, private navCtrl: NavController, private orderService: OrderService) {
    afAuth.user.subscribe(user => {
    console.log('🚀 ~ file: auth.service.ts ~ line 20 ~ AuthService ~ constructor ~ user', user);
      this.currentUserFirebase = user;
      if (user) {

        this.db.doc(`users/${user.uid}`).valueChanges().pipe(
          // take(1), disabled .. keep currentUser up to date
          tap((res: any) => {
            this.currentUser.next(res);
          })
        ).subscribe();
      }
    });

    this.user = afAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          console.log('auth logged in');
          return this.db.doc(`users/${user.uid}`).valueChanges();
        } else {
          this.signInAnonymously();
          return of(null);
        }
      })
    );
    this.user.subscribe(res => {
      console.log('auth service', res);
      this.userObject = res;
    });
  }

  signIn(credentials): Observable<any> {
    let mergeUid = null;
    const oldCurrentUser = this.currentUserFirebase;
    if (this.currentUserFirebase.isAnonymous) {
      mergeUid = this.currentUserFirebase.uid;
    }
    return from(this.afAuth.signInWithEmailAndPassword(credentials.email, credentials.password)).pipe(
      switchMap( user => {
        if (user) {
          if (mergeUid != null && mergeUid !== user.user.uid) {
             this.user.subscribe(res => {
              this.orderService.convertOrderDataToNewAccount(mergeUid, user.user.uid, res);
              oldCurrentUser.delete();
            });
          }
          return this.db.doc(`users/${user.user.uid}`).valueChanges();
        } else {
          return of(null);
        }
      })
    );
  }

  async signInAnonymously(){
    console.log('sign in anonym');
    this.afAuth.signInAnonymously();
  }

  signInFacebook(): Observable<any> {
    let mergeUid = null;
    const oldCurrentUser = this.currentUserFirebase;
    if (this.currentUserFirebase.isAnonymous) {
      mergeUid = this.currentUserFirebase.uid;
    }
    return from(this.afAuth.signInWithPopup( new firebase.auth.FacebookAuthProvider ).then(async user => {
      console.log('fbLogin:', user);
      if (mergeUid != null && mergeUid !== user.user.uid) {
        await this.user.subscribe(res => {
          this.orderService.convertOrderDataToNewAccount(mergeUid, user.user.uid, res);
          oldCurrentUser.delete();
        });
      }
      if (user.additionalUserInfo.isNewUser) {
        return this.db.doc(`users/${user.user.uid}`).set({
          name: user.additionalUserInfo.profile['name'],
          email: user.additionalUserInfo.profile['email'],
          picture: user.additionalUserInfo.profile['picture']['data']['url'],
          created: firebase.firestore.FieldValue.serverTimestamp(),
          role: 'GUEST'
        });
      } else {
        return this.db.doc(`users/${user.user.uid}`).update({
          name: user.additionalUserInfo.profile['name'],
          email: user.additionalUserInfo.profile['email'],
          picture: user.additionalUserInfo.profile['picture']['data']['url']
        });
      }
    }));
  }

  signInGoogle(): Observable<any> {
    let mergeUid = null;
    const oldCurrentUser = this.currentUserFirebase;
    if (this.currentUserFirebase.isAnonymous) {
      mergeUid = this.currentUserFirebase.uid;
    }
    return from(this.afAuth.signInWithPopup( new firebase.auth.GoogleAuthProvider ).then(async user => {
      console.log('fbGoogle:', user);
      if (mergeUid != null && mergeUid !== user.user.uid) {
        await this.user.subscribe(res => {
          this.orderService.convertOrderDataToNewAccount(mergeUid, user.user.uid, res);
          oldCurrentUser.delete();
        });
      }
      if (user.additionalUserInfo.isNewUser) {
        return this.db.doc(`users/${user.user.uid}`).set({
          name: user.additionalUserInfo.profile['name'],
          email: user.additionalUserInfo.profile['email'],
          picture: user.additionalUserInfo.profile['picture'],
          created: firebase.firestore.FieldValue.serverTimestamp(),
          role: 'GUEST'
        });
      } else {
        return this.db.doc(`users/${user.user.uid}`).update({
          name: user.additionalUserInfo.profile['name'],
          email: user.additionalUserInfo.profile['email'],
          picture: user.additionalUserInfo.profile['picture']
        });
      }
    }));
  }



  signUp(credentials) {
    return this.afAuth.createUserWithEmailAndPassword(credentials.email, credentials.password).then(data => {
      console.log('after signup', data);
      return this.db.doc(`users/${data.user.uid}`).set({
        name: credentials.name,
        email: credentials.email,
        role: credentials.role,
        created: firebase.firestore.FieldValue.serverTimestamp()
      });
    });
  }

  signOut() {
    this.afAuth.signOut().then(res => {
      this.navCtrl.navigateRoot('/');
    });
  }

  getAuthState(): Observable<any> {
   return of (this.afAuth.authState.subscribe(state => {
   console.log('🚀 ~ file: auth.service.ts ~ line 154 ~ AuthService ~ getAuthState ~ state', state);
      return of(state['uid']);
    }));
  }

  addPlace(id) {
    return this.db.doc(`users/${this.currentUserFirebase.uid}`).update({
      placeId: id
    });
  }

  tableAdded(isTrue: boolean) {
    let setup = null;
    if (this.userObject?.setup) {
      setup = this.userObject?.setup;
      setup.tablesEntered = true;
    } else {
      setup = {
        tablesEntered: true
      };
    }
    this.db.doc(`users/${this.currentUserFirebase.uid}`).update({
      setup
    });
  }
  productAdded(isTrue: boolean) {
    console.log('🚀 ~ file: auth.service.ts ~ line 180 ~ AuthService ~ productAdded ~ isTrue', isTrue);
    let setup = null;
    if (this.userObject?.setup) {
      setup = this.userObject?.setup;
      setup.productsEntered = true;
    } else {
      setup = {
        productsEntered: true
      };
    }
    this.db.doc(`users/${this.currentUserFirebase.uid}`).update({
      setup
    });
  }
}
