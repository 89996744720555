// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyD6u3qWEhx9S_u5O1M4cwUB_0OEr7exeCU',
    authDomain: 'orderblitz-dev.firebaseapp.com',
    projectId: 'orderblitz-dev',
    storageBucket: 'orderblitz-dev.appspot.com',
    messagingSenderId: '453307546418',
    appId: '1:453307546418:web:f0b7e4e2ec7aff8093352b',
    measurementId: 'G-STLFJ1K817'
  },
  website: 'https://dev.orderblitz.net/',
  stripeKey: 'pk_test_51IsQ3ZIkmFSLt4ebBkMTSKuvkZLgHQwIR9PY3eWCDjxj77VD3pn8sNTJDd5iWCKz7tbumXtv7mZiX9zMuQiLMLKy00BvnYWtno'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
