import { Observable, from, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import auth from 'firebase/app';
import 'firebase/auth';
import firebase from 'firebase/app';
@Injectable({
  providedIn: 'root'
})
export class OrderService {

  table: null;
  user = '';

  constructor(private db: AngularFirestore) { }

  async createOrder(cart): Promise<any> {
    let totalOrder = 0;
    const dateObject = new Date();
    cart.map((item: any) => {
      item.totalOrder = item.amount * Number(item.price);
      totalOrder += item.totalOrder;
      delete item.product;
    });

    const order =  {
      cart,
      totalOrder,
      table: this.table,
      creator: auth.auth().currentUser.uid,
      status: {status: 'new'},
      user: this.user,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      timeOffsetMinutes: dateObject.getTimezoneOffset()
    };
    let id = '';
    await this.db.collection('orders').add(order).then(res => {
      id = res.id;
    });
    return id;
  }

  async createOrderCallWaiter(): Promise<any> {

    const order =  {
      callWaiter: true,
      table: this.table,
      creator: auth.auth().currentUser.uid,
      status: {status: 'new'},
      user: this.user,
      createdAt: firebase.firestore.FieldValue.serverTimestamp()
    };
    let id = '';
    await this.db.collection('orders').add(order).then(res => {
      id = res.id;
    });
    return id;
  }

  async createOrderCallWaiterRequestInvoice(): Promise<any> {

    const order =  {
      callWaiter: true,
      requestInvoice: true,
      table: this.table,
      creator: auth.auth().currentUser.uid,
      status: {status: 'new'},
      user: this.user,
      createdAt: firebase.firestore.FieldValue.serverTimestamp()
    };
    let id = '';
    await this.db.collection('orders').add(order).then(res => {
      id = res.id;
    });
    return id;
  }

  getGuestOrder(orderId) {

    return this.db.doc(`orders/${orderId}`).valueChanges();
  }

  getGuestOrders() {
    return this.db.collection('orders', ref => ref.where('creator', '==', auth.auth().currentUser.uid).orderBy('createdAt', 'desc').limit(10)).valueChanges({idField: 'id'});
  }

  getAdminOrders(status) {
    // TODO add creator ref query
    switch (status) {
      case 'new': {
        return this.db.collection('orders', ref => ref.where('status.status', 'in', ['new', 'inProgress']).orderBy('createdAt', 'asc')).valueChanges({idField: 'id'});
        break;
      }
      case 'done': {
        return this.db.collection('orders', ref => ref.where('status.status', '==', 'done').orderBy('createdAt', 'desc').limit(3)).valueChanges({idField: 'id'});
        break;
      }
    }
    return this.db.collection('orders').valueChanges({idField: 'id'});
  }

  updateStatus(orderId, newStatus) {
    switch (newStatus) {
      case 'inProgress': {
        this.db.doc(`orders/${orderId}`).set({status: {status: newStatus, inProgressAt: firebase.firestore.FieldValue.serverTimestamp()}},{merge: true});
        break;
      }
      case 'done': {
        this.db.doc(`orders/${orderId}`).set({status: {status: newStatus, doneAt: firebase.firestore.FieldValue.serverTimestamp()}},{merge: true});
        break;
      }
    }

  }

  setTable(table) {
    this.table = table;
  }
  setUser(user) {
    this.user = user;
  }

  async convertOrderDataToNewAccount(oldUid, newUid, user) {
    await this.setUser(user);
    this.db.collection('orders', ref => ref.where('creator', '==', oldUid)).get().subscribe(res => {
      res.forEach(data => {
        console.log('data to Change', data);
        this.db.doc(`orders/${data.id}`).update({creator: newUid, user: this.user});
      });
    });
  }
}
