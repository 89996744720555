import { AutomaticLoginGuard } from './guards/automatic-login.guard';
import { RoleGuard } from './guards/role.guard';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AngularFireAuthGuard, redirectUnauthorizedTo, canActivate } from '@angular/fire/auth-guard';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule),
    canActivate: [AutomaticLoginGuard]
  },
  {
    path: 'guest_old',
    children: [
      {
        path: 'order/:id',
        canActivate: [AngularFireAuthGuard],
        loadChildren: () => import('./pages/guest/order/order.module').then( m => m.OrderPageModule)
      },
      {
        path: 'list',
        loadChildren: () => import('./pages/guest/guest-product-list/guest-product-list.module').then( m => m.GuestProductListPageModule)
      },
      {
        path: 'table2/:id',
        loadChildren: () => import('./pages/guest/guest-product-list/guest-product-list.module').then( m => m.GuestProductListPageModule)
      },
      {
        path: 'list/:id',
        loadChildren: () => import('./pages/guest/guest-product-details/guest-product-details.module').then( m => m.GuestProductDetailsPageModule)
      },
      {
        path: 'table/:id',
        loadChildren: () => import('./pages/guest/menu/menu.module').then( m => m.MenuPageModule)
      },
      {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full'
      },
    ]
  },
  {
    path: 'guest',
    loadChildren: () => import('./pages/guest/menu/menu.module').then( m => m.MenuPageModule)
  },
  {
    path: 'admin',
    loadChildren: () => import('./pages/admin/admin-menu/admin-menu.module').then( m => m.AdminMenuPageModule),
    canActivate: [AngularFireAuthGuard, RoleGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
      role: 'OWNER'
    }
  },  {
    path: 'order-modal',
    loadChildren: () => import('./pages/guest/order-modal/order-modal.module').then( m => m.OrderModalPageModule)
  }

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
