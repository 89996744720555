import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { take, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AutomaticLoginGuard implements CanActivate {

  constructor(private router: Router, private auth: AuthService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.auth.user.pipe(
      take(1),
      map(user => {
        if (!user) {
          return true;
        } else {
          const role = user['role'];
          if (role === 'ADMIN' || role === 'OWNER') {
            this.router.navigateByUrl('/admin');
          } else if (role === 'GUEST') {
            this.router.navigateByUrl('/guest/overview');
          }
          return true;
        }
      })
    );
  }

}
